<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading" :can-cancel="false" />
        <div v-if="isLive" class="alert alert-danger" style="text-align: center; font-weight: bold;" role="alert">
            You are Managing the Live Server Data
        </div>
        <CCard>
            <CCardHeader>
                <slot name="header">
                    <CIcon name="cil-people"/> Players
                    <div class="card-header-actions">
                        <small class="text-muted">Total: {{ players.length }}</small>
                    </div>
                </slot>
            </CCardHeader>
            <CCardBody>
                <CDataTable
                        :items="players"
                        :fields="playerFields"
                        :hover="hover"
                        column-filter
                        items-per-page-select
                        :items-per-page="10"
                        sorter
                        pagination >
                    <template #flagV="{item}">
                        <td>
                            <CBadge :color="$config.userClr(item.flags)">{{ $config.userTxt(item.flags) }}</CBadge>
                        </td>
                    </template>
                    <template #created="{item}">
                        <td class="py-2">
                            {{ item.created | formatDate }}
                        </td>
                    </template>
                    <template #manage="{item}">
                        <td class="py-2">
                            <CButton
                                    color="primary"
                                    variant="outline"
                                    square
                                    size="sm"
                                    @click="managePlayer(item.id)">
                                Manage
                            </CButton>
                        </td>
                    </template>
                </CDataTable>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "Players",
        components: {
            Loading
        },
        data(){
            return {
                players: [],
                playerFields: [
                    { key: "userName", label: "Username" },
                    { key: 'email', label: 'Email' },
                    { key: 'flagV', label: 'Flag', filter: false, sorter: false },
                    { key: 'characterSlots', label: "Slots", filter: false, sorter: false },
                    { key: 'id', label: "Key", filter: false, sorter: false },
                    { key: 'created', label: 'Created', filter: false, sorter: false },
                    { key: 'manage', label: '', filter: false, sorter: false },
                ],
                hover: true,
                isLoading: false
            }
        },
        computed: {
            isLive: function () {
                return (localStorage.env === "live");
            }
        },
        methods: {
            getUsers() {
                this.isLoading = true;
                this.$http.get(this.$config.gameapi + '/Player/' + localStorage.env, { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.players = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.isLoading = false;
                });
            },
            managePlayer(id) {
                this.$router.push({ name: 'Player', params: { id: id } })
            },
        },
        created() {
            this.getUsers();
        }
    }
</script>

<style scoped>

</style>
